MM.Command.ExportToIndent = Object.create(MM.Command, {
  label: { value: 'Export To Indent' }
});

function __truncate(string, l) {
  var ret;
  if (string.length > l) {
    ret = string.substring(0, l) + '...';
  } else {
    ret = string;
  }
  return $('<div>').text(ret).html();
}

const I = (_to_Indent = {
  options: {
    status: true,
    notes: true,
    notesaddtab: true,
    notesexclude: true
  },
  // TODO: build can attach to prototype of item for easier managing
  addidentation: function (identation, item, pos) {
    pos = pos || 0;
    let text = item.getText();
    let realtext = text;
    const status = item.getStatus();
    const childrens = item.getChildren(),
      cl = childrens.length;
    text = I.options.status && status === 'no' ? `[!] ${text}` : text;
    let suffix = '';
    const prefix = identation >= 2 ? '    '.repeat(identation - 2) : '';
    const npfx = identation >= 2 ? '    '.repeat(identation - 1) : '';

    let raw = '';
    let notes = item.getNotes();
    // If text is multiple lines, use it as notes
    if (text.includes("<div")) {
      notes = `${text}`;
      text = `...`;
    }
    try {
      const regex = /<br\s*[\/]?>/gi;
      let fulltext = notes || '';
      if (fulltext.trim()) {
        fulltext = '<div>' + fulltext + '</div>';
        fulltext = fulltext
          .replace(regex, '\n')
          .replaceAll('</div>', '</div>\n');
        raw = $(fulltext).text();
      }
      // const it = $(fulltext).get(0);
      // const text = it.innerText || it.textContent;
      // raw = text;
    } catch (e) {}
    notes = raw || notes || '';
    const force =
      I.options.notesexclude &&
      ['logs', 'attachments'].includes(realtext.toLowerCase());


    // Add prefix to Notes
    if (I.options.notesaddtab && notes.trim()) {
      notes = '```\n' + notes + '\n```';
    }

    suffix =
      (I.options.notes || force) && notes.trim() ? `Notes: \n${notes}\n` : ``;
    // sfx for markup to new line
    const sfx = suffix ? '  ' : '';
    // Add prefix to Notes
    if (I.options.notesaddtab && suffix && npfx) {
      // Add more tab also
      suffix = suffix
        .split('\n')
        .map((i) => npfx + i)
        .join('\n');
    }

    if (identation == 0) {
      let s = '# ' + text + `${sfx}\n${suffix}`;
      //s += 'Items: \n';
      return s;
    }
    if (identation == 1) {
      var addbr = '';
      if (pos > 0) {
        addbr = '\n';
      }
      text = __truncate(text, 100);
      const checked =
        I.options.status && status === 'yes' ? `~~${text}.~~` : text;
      return addbr + `## ${checked}` + `${sfx}\n${suffix}`;
    }
    if (identation == 2) {
      const checked =
        I.options.status && status === 'yes' ? `~~${text}.~~` : text;
      return (
        (pos + 1).toString() +
        `. ${checked}` +
        (cl ? ':' : '') +
        `${sfx}\n${suffix}`
      );
    }
    const checked =
      I.options.status && status === 'yes'
        ? ' [x]'
        : !status || status === '' || identation > 3
        ? ''
        : ' [ ]';
    return prefix + `-${checked} ${text}` + `${sfx}\n${suffix}`;
  },
  build: function (item, identation, pos) {
    identation = identation || 0;
    // Get text and print as headline
    var _return = this.addidentation(identation, item, pos);
    // Loop for all children
    // Get content and put below headline
    var _child_return = '';
    var childrens = item.getChildren(),
      cl = childrens.length;
    var i = 0;
    for (i = 0; i <= cl - 1; i++) {
      _child_return += _to_Indent.build(childrens[i], identation + 1, i);
    }

    // If content is single line, put right after headline
    _child_return = "<div class='theitemgroups'>" + _child_return + '</div>';
    _return += _child_return;

    return _return;
  }
});

const _refresh = () => {
  var item = MM.App.current;
  _to_Indent.options = $('.indentform')
    .serializeArray()
    .reduce((obj, { name, value }) => ({ ...obj, [value]: name }), {});
  var _out = _to_Indent.build(item);
  $('#exportindent pre').html(_out);
};

const _init = () => {
  $('.indentoptions').change(_refresh);
};

MM.Command.ExportToIndent.execute = function () {
  var item = MM.App.current;
  var _out = _to_Indent.build(item);
  var _select = `
    <form class="indentform">
    <fieldset>      
        <legend>Options</legend>      
        <input type="checkbox" class="indentoptions" name="indentoptions[]" checked="checked" value="status" id="idstatus"><label for="idstatus">Statuses</label><br>      
        <input type="checkbox" class="indentoptions" name="indentoptions[]" checked="checked" value="notes" id="idnotes"><label for="idnotes">Notes</label><br>      
        <input type="checkbox" class="indentoptions" name="indentoptions[]" checked="checked" value="notesaddtab" id="idnotesaddtab"><label for="idnotesaddtab">Add tabs to notes</label><br>      
        <input type="checkbox" class="indentoptions" name="indentoptions[]" checked="checked" value="notesexclude" id="idnotesexclude"><label for="idnotesexclude">Still show Logs's Notes</label><br>      
    </fieldset> 
    <form>
  `;
  var _export = `<div id="exportindent" class='export' style='overflow:auto; max-height: 80vh;'>
    ${_select}
    <pre>
    </pre></div>`;
  MM.App.stophandle = true;
  $('<div id="dialog-form" title="Indent Exportation"></div>')
    .append(_export)
    .appendTo($('body'))
    .dialog({
      modal: true,
      buttons: {
        Ok: function () {
          $(this).dialog('close');
        },
        Select: function () {
          var $this = $(this);
          $('#exportindent pre').css('user-select', 'all').selectText();
        }
      },
      open: function (event, ui) {},
      close: function (event, ui) {
        $(this).remove();
        MM.App.stophandle = false;
      }
    });
  _init();
  _refresh();
};
